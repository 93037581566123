import React from "react"
import { Link } from "gatsby"

import Image from "../components/image"
import Layout from "../components/layout"

import { FaChartLine, FaUser, FaTabletAlt, FaCheck } from "react-icons/fa"

const PricingPage = () => (
  <Layout>
    <div className="title is-2">Pricing</div>

    <div className="columns is-multiline">
      <div className="column is-third">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">Standard</p>
          </header>
          <div className="card-content">
            <div className="content">
              <ul>
                <li>$250 one-time setup fee</li>
                <li>$30 per month for hosting</li>
                <li>No contracts</li>
                <li>Domain name included</li>
                <li>Static website</li>
                <li>Some add-ons available</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="column is-third">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">Pro</p>
          </header>
          <div className="card-content">
            <div className="content">
              <ul>
                <li>$400 one-time setup fee</li>
                <li>$50 per month for hosting</li>
                <li>No contracts</li>
                <li>Domain name included</li>
                <li>CMS backed website - change content on demand</li>
                <li>A/B testing</li>
                <li>Custom forms</li>
                <li>All add-ons available</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="column is-third">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">Custom</p>
          </header>
          <div className="card-content">
            <div className="content">
              <ul>
                <li>Charges depend on requirements</li>
                <li>Call to setup an appointment</li>
                <li>Add-ons available</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
    <br />
    <hr />

    <div className="title is-2">Features</div>
    <div className="">

      <table className="table is-hoverable">
        <thead>
          <tr>
            <th>Feature</th>
            <th>Basic</th>
            <th>Pro</th>
            <th>Custom</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <th>No contracts<br /><span className="is-size-7 has-text-grey">cancel anytime for any reason</span></th>
            <td>
              <span className="icon"><FaCheck /></span>
            </td>
            <td><span className="icon"><FaCheck /></span></td>
            <td><span className="icon"><FaCheck /></span></td>
          </tr>
          <tr>
            <th>SEO optimized<br /><span className="is-size-7 has-text-grey">robots.txt, sitemap.xml, Google Search Console</span></th>
            <td>
              <span className="icon"><FaCheck /></span>
            </td>
            <td><span className="icon"><FaCheck /></span></td>
            <td><span className="icon"><FaCheck /></span></td>
          </tr>
          <tr>
            <th>Responsive<br /><span className="is-size-7 has-text-grey">works on phones, tablets, and desktops</span></th>
            <td>
              <span className="icon"><FaCheck /></span>
            </td>
            <td><span className="icon"><FaCheck /></span></td>
            <td><span className="icon"><FaCheck /></span></td>
          </tr>
          <tr>
            <th>Custom domain name<br /><span className="is-size-7 has-text-grey">e.g. [yourcompany].com</span></th>
            <td>
              <span className="icon"><FaCheck /></span>
            </td>
            <td><span className="icon"><FaCheck /></span></td>
            <td><span className="icon"><FaCheck /></span></td>
          </tr>
          <tr>
            <th>Audited for performance, accessibility, seo, and best practices<br /><span className="is-size-7 has-text-grey">via Google's lighthouse tool</span></th>
            <td>
              <span className="icon"><FaCheck /></span>
            </td>
            <td><span className="icon"><FaCheck /></span></td>
            <td><span className="icon"><FaCheck /></span></td>
          </tr>
          <tr>
            <th>Custom forms<br /><span className="is-size-7 has-text-grey"></span></th>
            <td><span className="icon"></span></td>
            <td><span className="icon"><FaCheck /></span></td>
            <td><span className="icon"><FaCheck /></span></td>
          </tr>
          <tr>
            <th>Content mgmt system (CMS)<br /><span className="is-size-7 has-text-grey">control your content on demand</span></th>
            <td><span className="icon"></span></td>
            <td><span className="icon"><FaCheck /></span></td>
            <td><span className="icon"><FaCheck /></span></td>
          </tr>
          <tr>
            <th>Custom integrations<br /><span className="is-size-7 has-text-grey">link to Facebook, PayPal, etc</span></th>
            <td><span className="icon"></span></td>
            <td><span className="icon"><FaCheck /></span></td>
            <td><span className="icon"><FaCheck /></span></td>
          </tr>
          <tr>
            <th>A/B Testing<br /><span className="is-size-7 has-text-grey">split traffic across different versions of website to test user engagement</span></th>
            <td><span className="icon"></span></td>
            <td><span className="icon"><FaCheck /></span></td>
            <td><span className="icon"><FaCheck /></span></td>
          </tr>
          <tr>
            <th>eCommerce<br /><span className="is-size-7 has-text-grey">take payment online</span></th>
            <td><span className="icon"></span></td>
            <td><span className="icon"></span></td>
            <td><span className="icon"><FaCheck /></span></td>
          </tr>
        </tbody>
      </table>
      
    </div>

    <br />
    <br />
    <hr />

    <div className="title is-2">Add-ons</div>

    <p>We can add any or all of these add-ons to your website - no matter which pricing plan you have.</p>
    <p>Prices vary depening on scale and complexity.</p>
    <br />
    <div className="card">
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <span className="icon">
              <FaChartLine size={28} />
            </span>
          </div>
            <p className="title is-4">Custom Analytics</p>
        </div>

        <div className="content">
          We can provide you with reports that tell you anything and everything
          about your website's performance
          <ul>
            <li>
              How many people saw my website in search results (i.e. Google)
            </li>
            <li>How many people clicked on my website from search results</li>
            <li>How many people were using their phones</li>
            <li>Which pages of my site are people looking at the most</li>
            <li>much, much, more</li>
          </ul>
        </div>
      </div>
    </div>

    <br />
    <br />

    <div className="card">
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <span class="icon">
              <FaUser size={28} />
            </span>
          </div>
            <p className="title is-4">Identity</p>
        </div>
        <div className="content">
          Control access to sections of your website with userids and passwords
          <ul>
            <li>Create gated content</li>
            <li>Create members only product pages</li>
            <li>Build custom mailing lists</li>
            <li>much, much, more</li>
          </ul>
        </div>
      </div>
    </div>

    <br />
    <br />

    <div className="card">
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <span class="icon">
              <FaTabletAlt size={28} />
            </span>
          </div>
            <p className="title is-4">Progressive Wep Apps</p>
        </div>
        <div className="content">
          We can build your website as a progressive web app
          <ul>
            <li>
              FIRE - Fast, Integrated, Reliable, and Engaging (
              <a href="https://developers.google.com/web/updates/2018/05/beyond-spa">
                tech details according to Google
              </a>
              )
            </li>
            <li>Make your website feel like a native application</li>
            <li>
              Make your website work even when the user doesn't have network
              service
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)

export default PricingPage
